import React from "react";
import Address from "./Address/Address";
import ContactUs from "./ContactUs/ContactUs";

function ContactPage() {
  return (
    <div>
      <ContactUs />
      <Address />
    </div>
  );
}

export default ContactPage;
