import React from "react";
import NavBar from "../NavBar/NavBar";
import CounterUp from "./CounterUp/CounterUpp";
import HowItHelps from "./HowItHelps/HowItHelps";
import LandingPage from "./LandingPage/LandingPage";
import OurFeatures from "./OurFeatures/OurFeatures";
import Patners from "./Patners/Patners";
import Solutions from "./Solutions/Solutions";

function Home() {
  return (
    <div>
      <LandingPage />
      <HowItHelps />
      <CounterUp />
      <Patners />
      <OurFeatures />
      {/* <Solutions /> */}
    </div>
  );
}

export default Home;
