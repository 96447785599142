import React from "react";
import Styles from "./Services.module.css";
import partner from "../../../Images/servicepage.png";
// import amazon from "../../../Images/amazon.png";
// import delhivery from "../../../Images/delhivery.png";
// import myntra from "../../../Images/myntra.png";
// import flipkart from "../../../Images/Flipkart.png";
// import wakefit from "../../../Images/wakefit.png";
// import guarented from "../../../Images/guarented.png";
// import Shadowfax from "../../../Images/Shadowfax.png";

function Services() {
  return (
    <div className={Styles.Servicesmaindiv}>
      <div className={Styles.Servicesmaindiv2}>
        <div data-aos="fade-right" className={Styles.Servicesmaindiv21}>
          <p>Partner with Foxxmile.</p>
          <p>Perfect partner to meet all your delivery needs !</p>
          {/* <p>to meet all your delivery needs !</p> */}
        </div>
        <div data-aos="fade-left" className={Styles.Servicesmaindiv22}>
          <img src={partner} />
        </div>
      </div>
    </div>
  );
}

export default Services;
