import React from "react";
import Aboutus from "./Aboutus/Aboutus";
import CoreValue from "./CoreValue/CoreValue";
import Leadership from "./Leadership/Leadership";
import Ourvision from "./Ourvision/Ourvision";

function AboutPage() {
  return (
    <div>
      <Aboutus />
      {/* <Ourvision /> */}
      <CoreValue />
      {/* <Leadership /> */}
    </div>
  );
}

export default AboutPage;
