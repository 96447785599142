import React from "react";
import Services from "./Services/Services";
import Servicespart2 from "./Servicespart2/Servicespart2";

function ServicePage() {
  return (
    <div>
      <Services />
      <Servicespart2 />
    </div>
  );
}

export default ServicePage;
