import React, { useEffect } from "react";
import Styles from "./Rider.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import ridertop from "../../../Images/rider-image.png";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

function Rider() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.Aboutusmain}>
      <div className={Styles.Aboutusmainimgdiv2}></div>
      <div className={Styles.Aboutusmaintextdiv}>
        <div data-aos="zoom-in" className={Styles.riderh2p}>
          <h2>Join with us and start earning</h2>
          <p>Be part of india's fastest EV-growing delivery network</p>
          <NavItem id="ss" className={Styles.NavItemm22}>
            <NavLink exact tag={Link} to="/contactus">
              <Button variant="outlined">Partner with us Apply here</Button>
            </NavLink>
          </NavItem>
        </div>
        <div className={Styles.ridrimgdiv} data-aos="fade-left">
          <img src={ridertop} />
        </div>
      </div>
    </div>
  );
}

export default Rider;
