import React, { useEffect } from "react";
import Styles from "./Tech.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import tech from "../../../Images/tech3.png";
import laptopp from "../../../Images/laptopp.png";
import iph1 from "../../../Images/iph1.png";
import ridd from "../../../Images/main.png";
import s from "../../../Images/s.gif";
import yes from "../../../Images/yes.gif";

function Tech() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.Techmaindiv}>
      <div className={Styles.Techmaindivimg}>
        <div className={Styles.Techmaindivtext}>
          <p data-aos="fade-right">Repeated customers</p>
          <p data-aos="fade-right">with our</p>
          <p data-aos="fade-right">Faster delivery services</p>
        </div>
        <div className={Styles.Techmaindivshadow}></div>
        <img src={tech} />
      </div>
      <div className={Styles.Techmaindivshadowaptop}>
        <img className={Styles.laptopp} src={laptopp} />
      </div>

      <div className={Styles.Techmaindivh2p}>
        <h4>Easy to use</h4>
        <p>
          Simple & modern API to integrate with any e-commerce store or use our
          Merchant plugin. Integrates in hours, not days.
        </p>
      </div>

      <div className={Styles.Techmaindivmonb}>
        <div data-aos="fade-right">
          <img className={Styles.laptopp2} src={iph1} />
        </div>
        <div data-aos="fade-left" className={Styles.Techmaindivh4p}>
          <h4>Schedule delivery</h4>
          <p>
            Avoid losses due to cancellations as end
            <br /> customers can schedule a <br /> change of delivery slot.
          </p>
        </div>
      </div>

      <div className={Styles.Techmaindivmonb2}>
        <div data-aos="fade-left" className={Styles.Techmaindivh4p}>
          <h4>We rate your customer</h4>
          <p>
            Get data on end customer behavior
            <br /> to restrict/promote features <br /> like cash on delivery.
          </p>
        </div>
        <div>
          <img data-aos="fade-right" className={Styles.laptopp2} src={ridd} />
        </div>
      </div>

      <div className={Styles.Techmaindivmonb}>
        <div data-aos="fade-right">
          <img className={Styles.laptopp2} src={s} />
        </div>
        <div data-aos="fade-left" className={Styles.Techmaindivh4p}>
          <h4>Real time tracking</h4>
          <p>
            End customers can track the
            <br /> delivery status as well, <br /> improving end user
            experience.
          </p>
        </div>
      </div>

      <div className={Styles.Techmaindivmonb2}>
        <div data-aos="fade-left" className={Styles.Techmaindivh4p}>
          <h4>Route Optimization</h4>
          <p>
            Our route optimization algorithm
            <br /> ensures that we are able to <br /> deliver more in less time.
          </p>
        </div>
        <div data-aos="fade-right">
          <img className={Styles.laptopp2} src={yes} />
        </div>
      </div>
    </div>
  );
}

export default Tech;
