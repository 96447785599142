import React from "react";
import HowItWorks from "./HowItWorks/HowItWorks";
import Rider from "./Rider/Rider";
import RiderBenefits from "./RiderBenefits/RiderBenefits";
import Testimony from "./Testimony/Testimony";
import TopBrands from "./TopBrands/TopBrands";

function RiderPage() {
  return (
    <div>
      <Rider />
      {/* <TopBrands /> */}
      <RiderBenefits />
      {/* <HowItWorks /> */}
      {/* <Testimony /> */}
    </div>
  );
}

export default RiderPage;
