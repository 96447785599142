import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import AboutPage from "./Components/AboutPage/AboutPage";
import NavBar from "./Components/NavBar/NavBar";
import RiderPage from "./Components/RiderPage/RiderPage";
import CommingSoon from "./Components/CommingSoon";
import ServicePage from "./Components/ServicesPage/ServicePage";
import TechPage from "./Components/TechPage/TachPage";
import ContactPage from "./Components/ContactPage/ContactPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          {/* <Route path="/" element={<CommingSoon />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutPage />} />
          <Route path="/rider" element={<RiderPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/contactus" element={<ContactPage />} />
          <Route path="/tech" element={<TechPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
