import React from "react";
import Styles from "./HowItWorks.module.css";
import calender from "../../../Images/calendar.png";
import address from "../../../Images/address.png";
import deliveryman from "../../../Images/delivery-man.png";
import deliverytruck from "../../../Images/delivery-truck.png";

function HowItWorks() {
  return (
    <div className={Styles.HowItWorksmaindiv}>
      <div className={Styles.hiwh2div}>
        <h2>How it works?</h2>
      </div>
      <div className={Styles.hiwtextandboxmaindiv}>
        <div className={Styles.hiwtextandboxmainsubdiv}>
          <div className={Styles.hiwtextandboxmainsubdiv2}>
            <div className={Styles.hiwbox}>
              <img src={calender} />
            </div>
            <div className={Styles.hiwh3p}>
              <h3>Choose Your Own Schedule</h3>
              <p>Login to the Rider App and come online.</p>
            </div>
          </div>
          <div className={Styles.hiwtextandboxmainsubdiv2}>
            <div className={Styles.hiwbox}>
              <img src={deliveryman} />
            </div>
            <div className={Styles.hiwh3p}>
              <h3>Pick Up Packages</h3>
              <p>Arrive at Sendfast warehouse and pickup the packages.</p>
            </div>
          </div>
        </div>
        <div className={Styles.hiwtextandboxmainsubdiv}>
          <div className={Styles.hiwtextandboxmainsubdiv2}>
            <div className={Styles.hiwbox}>
              {" "}
              <img src={deliverytruck} />
            </div>
            <div className={Styles.hiwh3p}>
              <h3>Deliver Packages</h3>
              <p>Deliver the packages within the scheduled time.</p>
            </div>
          </div>
          <div className={Styles.hiwtextandboxmainsubdiv2}>
            <div className={Styles.hiwbox}>
              {" "}
              <img src={address} />
            </div>
            <div className={Styles.hiwh3p}>
              <h3>Reach Your Destination</h3>
              <p>
                Reach the destination using our Route Optimized rider
                navigation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
