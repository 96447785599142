import React, { useEffect } from "react";
import Styles from "./LandingPage.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import landimg from "../../../Images/hero-image.png";
import car from "../../../Images/trk.png";
import back from "../../../Images/back.png";
import sco from "../../../Images/sco.png";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

function LandingPage() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div id="home" className={Styles.landingmaindiv}>
      <div data-aos="zoom-in" className={Styles.landingmaindiv2}>
        <div className={Styles.landingmaindi3}>
          <h1>Making way to</h1>
          <h1>Zero-carbon logistics.</h1>
        </div>
        <div className={Styles.landingmaindiv4}>
          <p>Speedy, secure and sustainable deliveries!</p>
        </div>
        <div className={Styles.landingmaindiv5}>
          <NavItem id="ss" className={Styles.NavItemm22}>
            <NavLink href="#clients">
              <Button className={Styles.btn1} variant="contained">
                Trusted Clients
              </Button>
            </NavLink>
          </NavItem>
        </div>
      </div>
      <div data-aos="fade-right" className={Styles.landingmaindiv12}>
        <img src={back} />
        <img className={Styles.carclass} src={car} />
        <img className={Styles.scoclass} src={sco} />
      </div>
    </div>
  );
}

export default LandingPage;
