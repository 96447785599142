import React, { useEffect } from "react";
import Styles from "./Aboutus.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import abtus from "../../../Images/aboutus-image.png";

function Aboutus() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div id="aboutus" className={Styles.Aboutusmain}>
      <div data-aos="fade-right" className={Styles.Aboutusmaintextdiv}>
        <h2>About us</h2>
        <p>
          Foxxmile’s mission is to make deliveries seamless and sustainable .
          Our objective is to become a go-to partner to the businesses by taking
          care of all their logistic needs and providing them with unparalleled
          solutions acceding to our core values of reliability, accuracy,
          integrity, and quality.
        </p>

        <p>
          The world has seen a massive surge in demand for last-mile deliveries
          in the last two years. Whether it is e-commerce, food, retail,
          grocery, or home delivery services, having the right logistics partner
          will put the business on the winning wheel. Not just being the right
          logistics partner, but we want to create a meaningful impact by also
          being socially responsible towards the environment to limit the
          devastating impacts of supply chain activities on this planet.
          Adapting to electric vehicle fleet is one such major road to make this
          possible.
        </p>
        <p>
          Our team deeply passionate about delivering high quality services and
          creating social impact. At Foxxmile ,we are committed to fabricating
          success stories for your businesses and our planet.
        </p>
        {/* <h4 className={Styles.abtshash}># Creating the change</h4> */}
      </div>
      <div data-aos="zoom-in" className={Styles.Aboutusmainimgdiv}>
        <img src={abtus} />
      </div>
    </div>
  );
}

export default Aboutus;
