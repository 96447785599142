import React, { useEffect, useState, useCallback } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "../../App.css";
import Styles from "./NavBar.module.css";
import logo from "../../Images/logo1.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function NavBar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    console.log("toggle", !isOpen);
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   const logoo = document.querySelectorAll(".logosvg");
  //   for (var i = 0; i < logoo.length; i++) {
  //     console.log(`Letter $(i) is ${logo[i].getTotalLength()}`);
  //   }
  // }, []);
  return (
    <div className={Styles.navmaindivmain}>
      <Navbar className={Styles.navmaindiv} color="light" light expand="md">
        <NavbarBrand
          className={Styles.navcurs}
          exact
          tag={Link}
          activeClassName={Styles.active}
          to="/"
        >
          <img className={Styles.navlogo} src={logo} />
          {/* <svg
            className={Styles.logosvg}
            width="594"
            height="111"
            viewBox="0 0 594 111"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5703 6.40625V108.781H0V6.40625H13.5703ZM56.4609 52.4609V63.5703H10.6172V52.4609H56.4609ZM63.4219 6.40625V17.5156H10.6172V6.40625H63.4219Z"
              fill="black"
            />
            <path
              d="M158.414 54.3594V60.8281C158.414 68.5156 157.453 75.4062 155.531 81.5C153.609 87.5938 150.844 92.7734 147.234 97.0391C143.625 101.305 139.289 104.562 134.227 106.812C129.211 109.062 123.586 110.188 117.352 110.188C111.305 110.188 105.75 109.062 100.688 106.812C95.6719 104.562 91.3125 101.305 87.6094 97.0391C83.9531 92.7734 81.1172 87.5938 79.1016 81.5C77.0859 75.4062 76.0781 68.5156 76.0781 60.8281V54.3594C76.0781 46.6719 77.0625 39.8047 79.0312 33.7578C81.0469 27.6641 83.8828 22.4844 87.5391 18.2188C91.1953 13.9062 95.5312 10.625 100.547 8.375C105.609 6.125 111.164 5 117.211 5C123.445 5 129.07 6.125 134.086 8.375C139.148 10.625 143.484 13.9062 147.094 18.2188C150.75 22.4844 153.539 27.6641 155.461 33.7578C157.43 39.8047 158.414 46.6719 158.414 54.3594ZM144.984 60.8281V54.2188C144.984 48.125 144.352 42.7344 143.086 38.0469C141.867 33.3594 140.062 29.4219 137.672 26.2344C135.281 23.0469 132.352 20.6328 128.883 18.9922C125.461 17.3516 121.57 16.5312 117.211 16.5312C112.992 16.5312 109.172 17.3516 105.75 18.9922C102.375 20.6328 99.4688 23.0469 97.0312 26.2344C94.6406 29.4219 92.7891 33.3594 91.4766 38.0469C90.1641 42.7344 89.5078 48.125 89.5078 54.2188V60.8281C89.5078 66.9688 90.1641 72.4062 91.4766 77.1406C92.7891 81.8281 94.6641 85.7891 97.1016 89.0234C99.5859 92.2109 102.516 94.625 105.891 96.2656C109.312 97.9062 113.133 98.7266 117.352 98.7266C121.758 98.7266 125.672 97.9062 129.094 96.2656C132.516 94.625 135.398 92.2109 137.742 89.0234C140.133 85.7891 141.938 81.8281 143.156 77.1406C144.375 72.4062 144.984 66.9688 144.984 60.8281Z"
              fill="black"
            />
            <path
              d="M290.18 6.40625H303.328L336.867 89.8672L370.336 6.40625H383.555L341.93 108.781H331.664L290.18 6.40625ZM285.891 6.40625H297.492L299.391 68.8438V108.781H285.891V6.40625ZM376.172 6.40625H387.773V108.781H374.273V68.8438L376.172 6.40625Z"
              fill="black"
            />
            <path
              d="M426.164 6.40625V108.781H412.594V6.40625H426.164Z"
              fill="black"
            />
            <path
              d="M512.93 97.7422V108.781H461.742V97.7422H512.93ZM464.414 6.40625V108.781H450.844V6.40625H464.414Z"
              fill="black"
            />
            <path
              d="M593.508 97.7422V108.781H539.297V97.7422H593.508ZM542.039 6.40625V108.781H528.469V6.40625H542.039ZM586.336 50.4219V61.4609H539.297V50.4219H586.336ZM592.805 6.40625V17.5156H539.297V6.40625H592.805Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M188.209 0.878756L223.721 36.2468L216.588 43.2555L181.076 7.88744L188.209 0.878756Z"
              fill="#00DCF6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M231.759 44.3431L267.583 79.3951L260.512 86.4667L224.688 51.4147L231.759 44.3431Z"
              fill="#00DCF6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M232.607 63.924L268.775 98.6215L261.774 105.762L225.606 71.0649L232.607 63.924Z"
              fill="#00DCF6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M266.8 7.51429L231.735 43.3257L224.59 36.3295L259.655 0.518074L266.8 7.51429Z"
              fill="#00DCF6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M223.817 51.5307L188.752 87.3421L181.607 80.3459L216.672 44.5344L223.817 51.5307Z"
              fill="#00DCF6"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M224.79 71.072L189.399 106.561L182.319 99.5001L217.709 64.0107L224.79 71.072Z"
              fill="#00DCF6"
            />
          </svg> */}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className={Styles.navCollap} className="ml-auto" navbar>
            <div className={Styles.navMidEnddiv}>
              <div className={Styles.navMiddiv}>
                <NavItem onClick={toggle} className={Styles.navItemm}>
                  <NavLink
                    exact
                    tag={Link}
                    activeClassName={Styles.active}
                    className={Styles.navItemm22}
                    to="/aboutus"
                  >
                    About us
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar className={Styles.navItemm}>
                  <DropdownToggle
                    // exact
                    // tag={Link}
                    // to="/services"
                    nav
                    caret
                    className={Styles.navItemm222}
                  >
                    Services
                  </DropdownToggle>
                  <DropdownMenu right className={Styles.navdrop}>
                    <DropdownItem
                      onClick={toggle}
                      className={Styles.navdrop3}
                      href={"/services/#serviceid1"}
                    >
                      Truck
                    </DropdownItem>
                    <DropdownItem
                      onClick={toggle}
                      className={Styles.navdrop3}
                      href={"/services/#serviceid2"}
                    >
                      Rider
                    </DropdownItem>
                    <DropdownItem
                      onClick={toggle}
                      className={Styles.navdrop3}
                      href={"/services/#serviceid3"}
                    >
                      EV Fleet
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem onClick={toggle} className={Styles.navItemm}>
                  <NavLink
                    exact
                    tag={Link}
                    activeClassName={Styles.active}
                    className={Styles.navItemm22}
                    to="/rider"
                  >
                    Rider
                  </NavLink>
                </NavItem>
                <NavItem onClick={toggle} className={Styles.navItemm}>
                  <NavLink
                    exact
                    tag={Link}
                    activeClassName={Styles.active}
                    className={Styles.navItemm22}
                    to="/tech"
                  >
                    Tech
                  </NavLink>
                </NavItem>
                <NavItem onClick={toggle} className={Styles.navItemm2}>
                  <NavLink
                    exact
                    tag={Link}
                    to="/contactus"
                    activeClassName={Styles.active}
                    className={Styles.navItemm22}
                  >
                    Contact us
                  </NavLink>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Collapse>
        <NavItem id="ss" className={Styles.NavItemm22}>
          <NavLink exact tag={Link} to="/contactus">
            <Button className={Styles.NavItemmbtn} variant="contained">
              Contact us
            </Button>
          </NavLink>
        </NavItem>
      </Navbar>
    </div>
  );
}
