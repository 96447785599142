import React, { useEffect } from "react";
import Styles from "./OurFeatures.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import center from "../../../Images/center.png";
import computer from "../../../Images/computer.png";
import mapr from "../../../Images/mapr.png";
import map from "../../../Images/map.png";
import recal from "../../../Images/recal.png";

function OurFeatures() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.OurFeaturesmaindiv}>
      <div className={Styles.OurFeaturesmaindivhead}>
        <p>Our</p>
        <p> Features</p>
      </div>
      <div className={Styles.OurFeaturesmaindivhead12}>
        <div className={Styles.OurFeaturesmaindiv2}>
          <div className={Styles.OurFeaturesmaindiv3}>
            <div
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="2000"
              className={Styles.OurFeaturesmaindiv31}
            >
              <div>
                <img className={Styles.icn} src={computer} />
              </div>
              <div className={Styles.OurFeaturesmaindiv312}>
                <p>EASE OF USE</p>
                <p>
                  Simple API integration and robust platforms to help e-commerce
                  companies, merchants, network partners and vendors to get
                  started from day one.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-easing="linear"
              data-aos-duration="2000"
              className={Styles.OurFeaturesmaindiv31}
            >
              <div>
                <img className={Styles.icn} src={mapr} />
              </div>
              <div className={Styles.OurFeaturesmaindiv312}>
                <p>ROUTE OPTIMIZATION</p>
                <p>
                  Our proprietary route optimization algorithms ensure that we
                  are the most efficient player in the market.
                </p>
              </div>
            </div>
          </div>
          <div className={Styles.OurFeaturesmaindiv4}>
            <img
              data-aos="zoom-in-up"
              className={Styles.icncntr}
              src={center}
            />
          </div>
          <div className={Styles.OurFeaturesmaindiv5}>
            <div
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="2000"
              className={Styles.OurFeaturesmaindiv53}
            >
              <div>
                <img className={Styles.icn} src={map} />
              </div>
              <div className={Styles.OurFeaturesmaindiv3122}>
                <p>REAL TIME TRACKING</p>
                <p>
                  Track the real time status of your deliveries with the help of
                  our dashboard.
                </p>
              </div>
            </div>
            <div
              data-aos="fade-right"
              data-aos-easing="linear"
              data-aos-duration="2000"
              className={Styles.OurFeaturesmaindiv53}
            >
              <div>
                <img className={Styles.icn} src={recal} />
              </div>
              <div className={Styles.OurFeaturesmaindiv3122}>
                <p>BRAND RECALL</p>
                <p>
                  Our expertise and technologies ensure the best end user
                  experience which helps our clients increase their brand
                  recall.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurFeatures;
