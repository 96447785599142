import React, { useEffect } from "react";
import Styles from "./CoreValue.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import zeroemission from "../../../Images/electriccar.png";
import easy from "../../../Images/easyuse.png";
import instant from "../../../Images/handshake.png";
import statistics from "../../../Images/statisticss.png";

function CoreValue() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.CoreValuemainidv}>
      {/* <div className={Styles.CoreValuemaindiv2}>
        <h2>Our Core Value</h2>
      </div> */}
      <div className={Styles.CoreValuemaindiv3}>
        <div data-aos="fade-up" className={Styles.CoreValueimgtext}>
          <div className={Styles.CoreValueimgdiv}>
            <img src={zeroemission} />
          </div>
          <div className={Styles.CoreValues}>
            <p>No Emission</p>
          </div>
        </div>
        <div data-aos="fade-up" className={Styles.CoreValueimgtext}>
          <div className={Styles.CoreValueimgdiv}>
            <img src={easy} />
          </div>
          <div className={Styles.CoreValues}>
            <p className={Styles.CoreValuep}>Ease</p>
          </div>
        </div>
        <div data-aos="fade-up" className={Styles.CoreValueimgtext}>
          <div className={Styles.CoreValueimgdiv}>
            <img src={instant} />
          </div>
          <div>
            <div className={Styles.CoreValues}>
              <p>Reliability</p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className={Styles.CoreValueimgtext}>
          <div className={Styles.CoreValueimgdiv}>
            <img src={statistics} />
          </div>
          <div className={Styles.CoreValues}>
            <p>Efficiency</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreValue;
