import React from "react";
import Styles from "./Patners.module.css";
import zomato from "../../../Images/zomato.png";
import amazon from "../../../Images/amazon.png";
import delhivery from "../../../Images/delhivery.png";
import myntra from "../../../Images/myntra.png";
import flipkart from "../../../Images/Flipkart.png";
import wakefit from "../../../Images/wakefit.png";
import guarented from "../../../Images/guarented.png";
import Shadowfax from "../../../Images/Shadowfax.png";

import udan from "../../../Images/udan.png";
import waycool from "../../../Images/waycool.png";
import oladash from "../../../Images/oladash.png";

function Patners() {
  return (
    <div id="clients" className={Styles.partmaindiv}>
      <div data-aos="fade-up" className={Styles.parth2p}>
        <h2>Our clients</h2>
        <p>
          We are trusted by some of the biggest names in e-commerce industry.
        </p>
      </div>
      <div data-aos="fade-up" className={Styles.partmaindiv2}>
        <div>
          <div className={Styles.partp}>
            <div>
              <img className={Styles.icn} src={zomato} />
            </div>{" "}
            <div>
              <img className={Styles.icn} src={amazon} />
            </div>{" "}
            <div>
              <img className={Styles.icn} src={delhivery} />
            </div>{" "}
            <div>
              <img className={Styles.icn} src={myntra} />
            </div>
            <div>
              <img className={Styles.icn} src={udan} />
            </div>
            <div>
              <img className={Styles.icn} src={waycool} />
            </div>
          </div>
          <div className={Styles.partp}>
            <div>
              <img className={Styles.icn} src={flipkart} />
            </div>{" "}
            <div>
              <img className={Styles.icn} src={wakefit} />
            </div>{" "}
            <div>
              <img className={Styles.icn} src={guarented} />
            </div>{" "}
            <div>
              <img className={Styles.icn} src={Shadowfax} />
            </div>
            <div>
              <img className={Styles.icn} src={oladash} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Patners;
