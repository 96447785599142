import React from "react";
import Styles from "./RiderBenefits.module.css";
import riderbtm from "../../../Images//take-away.png";
import workschedule from "../../../Images/meeting.png";
import profit from "../../../Images/profit2.png";
import pzy from "../../../Images/salary.png";

function RiderBenefits() {
  return (
    <div data-aos="fade-up" className={Styles.RiderBenefitsmaindiv}>
      <div className={Styles.Rbheaddiv}>
        <h2>Why become our partner</h2>
      </div>
      <div className={Styles.rbmain2}>
        <div className={Styles.rbmain2img}>
          <img src={riderbtm} />
        </div>
        <div className={Styles.rbmain2textmain}>
          <div className={Styles.rbmain2bxtext}>
            <div className={Styles.rbmain2bx}>
              <img src={workschedule} />
            </div>
            <div className={Styles.rbmain2text}>
              <h3>Flexible Work Timings</h3>
              <p>Choose your work type, timing, and location.</p>
            </div>
          </div>
          <div className={Styles.rbmain2bxtext}>
            <div className={Styles.rbmain2bx}>
              <img src={profit} />
            </div>
            <div className={Styles.rbmain2text}>
              <h3>Flexible Payment Options</h3>
              <p>There is no limit to how much you can earn.</p>
              <p> You get paid per delivery.</p>
            </div>
          </div>
          <div className={Styles.rbmain2bxtext}>
            <div className={Styles.rbmain2bx}>
              <img src={pzy} />
            </div>
            <div className={Styles.rbmain2text}>
              <h3>Fixed Salary</h3>
              <p>Get an assured fixed minimum earning as well.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RiderBenefits;
