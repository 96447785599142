import React, { useEffect } from "react";
import Styles from "./Servicespart2.module.css";
import partner from "../../../Images/partner.png";
import rider3 from "../../../Images/riderr.png";
import track from "../../../Images/trukk.png";
import rider44 from "../../../Images/rider44.png";
import blt from "../../../Images/blt.png";
import Aos from "aos";
import "aos/dist/aos.css";

function Servicespart2() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.Servicesmaindiv}>
      <div className={Styles.Servicesmaindiv2}>
        <div
          data-aos="fade-left"
          className={Styles.Servicesmaindiv21}
          id="serviceid2"
        >
          <h4 className={Styles.Servicesmaindivpp}>
            Riders for your deliveries needs
          </h4>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Extensive pan india rider availability.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Reliable and experienced riders for hassle-free delivery.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Specialised in tier 2 and tier 3 cities.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Higher conversion rates.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; API integration for real time tracking.
          </p>
        </div>
        <div data-aos="fade-right" className={Styles.Servicesmaindiv22}>
          <img src={rider3} />
        </div>
      </div>
      <div
        data-aos="fade-right"
        className={Styles.Servicesmaindiv2}
        id="serviceid1"
      >
        <div className={Styles.Servicesmaindiv22}>
          <img src={track} />
        </div>
        <div data-aos="fade-left" className={Styles.Servicesmaindiv21}>
          <h4 className={Styles.Servicesmaindivpp}>
            Trucks for your deliveries needs
          </h4>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Trained drivers for efficient deliveries.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Trained workforce for COD handling.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Route optimization for quick deliveries.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Verified truck, deliveries and helpers.
          </p>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; API integration for real time tracking and dashboard access.
          </p>
        </div>
      </div>
      <div
        data-aos="fade-right"
        className={Styles.Servicesmaindiv2}
        id="serviceid3"
      >
        <div className={Styles.Servicesmaindiv21}>
          <h4 className={Styles.Servicesmaindivpp}>
            EV fleet for your green deliveries
          </h4>
          <p>
            <img src={blt} style={{ width: "15px" }} />
            &nbsp; Collaborate with us for sustainable Mobility by deploying our
            <br />
            e-bikes / etrucks for your delivery needs.
          </p>
          {/* <p></p> */}
        </div>
        <div data-aos="fade-left" className={Styles.Servicesmaindiv22}>
          <img src={rider44} />
        </div>
      </div>
    </div>
  );
}

export default Servicespart2;
