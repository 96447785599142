import React, { useEffect } from "react";
import Styles from "./OurLastMile.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import fragile from "../../../Images/fragile.png";
import monitorR from "../../../Images/monitorR.png";
import pin from "../../../Images/pin.png";
import verified from "../../../Images/verified.png";

function OurLastMile() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.OurLastMilemaindiv}>
      <div className={Styles.OLMh4}>
        <h4>HOW OUR LAST MILE PLATFORM WORKS</h4>
      </div>

      <div data-aos="fade-up" className={Styles.OLM2}>
        <div className={Styles.OLMhimgtext}>
          <div className={Styles.OLMhimg}>
            <img src={monitorR} />
          </div>
          <div className={Styles.OLMhtext}>
            <h4>Integrate with Sendfast API’s</h4>
            <p>
              Easy assisted integration with our APIs. Average integration time
              is 12 hours.
            </p>
          </div>
        </div>

        <div data-aos="fade-up" className={Styles.OLMhimgtext}>
          <div className={Styles.OLMhimg}>
            <img src={fragile} />
          </div>
          <div className={Styles.OLMhtext}>
            <h4>Send packages to us</h4>
            <p>
              Send packages to our warehouse and get a delivery confirmation
              about the receipt of the package.
            </p>
          </div>
        </div>
        <div data-aos="fade-up" className={Styles.OLMhimgtext}>
          <div className={Styles.OLMhimg}>
            <img src={pin} />
          </div>
          <div className={Styles.OLMhtext}>
            <h4>Real time tracking</h4>
            <p>
              Track the status of your package at all times from the dashboard.
            </p>
          </div>
        </div>
        <div data-aos="fade-up" className={Styles.OLMhimgtext}>
          <div className={Styles.OLMhimg}>
            <img src={verified} />
          </div>
          <div className={Styles.OLMhtext}>
            <h4>Delivered sucessfully</h4>
            <p>
              Our tech enabled platform and huge reach of network partners in
              Tier II and III ensures 100% successful delivery.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurLastMile;
