import React, { useEffect } from "react";
import Styles from "./Footer.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import logo from "../../Images/logo1.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";

function Footer() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.footermain}>
      <div className={Styles.footermain1}>
        <div className={Styles.footermain2}>
          <img className={Styles.logo} src={logo} />
        </div>
        <div className={Styles.footermain21}>
          <NavLink href={"/#home"}>
            <p>Home</p>
          </NavLink>

          <NavLink href={"/aboutus/#aboutus"}>
            <p>About us</p>
          </NavLink>
          <NavLink href={"/contactus/#contactus"}>
            <p>Contactus</p>
          </NavLink>
        </div>
        <div className={Styles.footermain22}>
          <p>FAQs</p>
          <p>Privacy policy</p>
          <p>Terms and Condition</p>
        </div>
        <div className={Styles.footermain23}>
          <div className={Styles.footermain23s}>
            <p>Social Links</p>
          </div>
          <div className={Styles.footerlog}>
            <InstagramIcon className={Styles.footerlog1} />
            <FacebookIcon className={Styles.footerlog2} />
            <YouTubeIcon className={Styles.footerlog3} />
          </div>
        </div>
      </div>
      <div className={Styles.footermain3}>
        <p>Copyrights © All rights reserved Tekkr pvt ltd</p>
      </div>
    </div>
  );
}

export default Footer;
