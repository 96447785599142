import React, { useEffect } from "react";
import Styles from "./HowItHelps.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import cod from "../../../Images/cod.png";
import customer from "../../../Images/customer.png";
import lastmile from "../../../Images/lastmile.png";
import reach from "../../../Images/reach.png";
import warehouse from "../../../Images/warehouse.png";

function HowItHelps() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.hwithlpsdiv}>
      <div data-aos="fade-up">
        <h2>What we do?</h2>
      </div>
      <div data-aos="zoom-in-down">
        <p>
          Our platform helps fulfills all delivery needs with speed and
          efficiency.
        </p>
      </div>
      <div className={Styles.iconstextmaindiv}>
        <div>
          <div className={Styles.iconstextmaindiv2}>
            <div className={Styles.iconstextimgdiv}>
              <img src={reach} />
              <p>REACH ACROSS COUNTRY</p>
              <p className={Styles.iconstextimgdivp2}>
                Our reach is spread across 500+ PIN codes all over India. Our
                speciality is our expansive reach in Tier II and III cities
                especially in South India.
              </p>
            </div>
            <div className={Styles.iconstextimgdiv}>
              <img src={customer} />
              <p>MIDDLE MILE</p>
              <p className={Styles.iconstextimgdivp2}>
                We offer vans and trucks to move products for our clients.
              </p>
            </div>
            <div className={Styles.iconstextimgdiv}>
              <img src={cod} />
              <p>COD HANDLING/REMITTANCE</p>
              <p className={Styles.iconstextimgdivp2}>
                We have a highly trained workforce and are able to remit the
                cash safely within 24 hours.
              </p>
            </div>
          </div>
          <div className={Styles.iconstextmaindiv2}>
            <div className={Styles.iconstextimgdiv}>
              <img src={lastmile} />
              <p>LAST MILE</p>
              <p className={Styles.iconstextimgdivp2}>
                We extend our hand to the clients by providing them with the
                bikers, vans, intercity & intracity transportation.
              </p>
            </div>
            <div className={Styles.iconstextimgdiv}>
              <img src={warehouse} />
              <p>WAREHOUSING</p>
              <p className={Styles.iconstextimgdivp2}>
                We provide hub space for LM delivery operations and for the
                inventory storage based on clients requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItHelps;
