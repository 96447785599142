import React from "react";
import OurLastMile from "./OurLastMile/OurLastMile";
import Tech from "./Tech/Tech";

function TechPage() {
  return (
    <div>
      <Tech />
      <OurLastMile />
    </div>
  );
}

export default TechPage;
