import React, { useEffect } from "react";
import Styles from "./ContactUs.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Button } from "@mui/material";
import contactus from "../../../Images/contactus.png";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";

function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div id="contactus" className={Styles.ContactUsmain}>
      <div className={Styles.ContactUsmaindiv}>
        <div className={Styles.ContactUsmaindiv1}>
          <h3>Conatct us</h3>
        </div>
        <div className={Styles.ContactUsmaindiv2}>
          <div className={Styles.ContactUsmaindiv21}>
            <TextField
              id="outlined-basic"
              label="Full Name"
              variant="outlined"
            />
            <TextField
              id="outlined-basic"
              label="Mobile Number"
              variant="outlined"
            />
          </div>
          <div className={Styles.ContactUsmaindiv22}>
            <TextField id="outlined-basic" label="Email" variant="outlined" />
            <TextField
              id="outlined-basic"
              label="Loaction"
              variant="outlined"
            />
          </div>
          <div className={Styles.ContactUsmaindiv23}>
            <TextField id="outlined-basic" label="Subject" variant="outlined" />
          </div>
          <div className={Styles.ContactUsmaindiv24}>
            <p>Message</p>
            <TextareaAutosize
              minRows={3}
              id="outlined-basic"
              label="Message"
              variant="outlined"
            />
          </div>
          <div className={Styles.ContactUsbtn}>
            <Button variant="contained">Submit</Button>
          </div>
        </div>
      </div>
      <div className={Styles.ContactUsmaindiv3}>
        <img src={contactus} />
      </div>
    </div>
  );
}

export default ContactUs;
