import React, { useEffect } from "react";
import Styles from "./Address.module.css";
import Aos from "aos";
import "aos/dist/aos.css";
import adds from "../../../Images/adds.png";
import MailIcon from "@mui/icons-material/Mail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import BusinessIcon from "@mui/icons-material/Business";

function Address() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className={Styles.Addressmaindiv}>
      <div className={Styles.AddressTextImage}>
        <div className={Styles.AddressImage}>
          <img src={adds} />
        </div>
        <div className={Styles.AddressText}>
          <h4>Get In Touch</h4>
          <p>
            <MailIcon className={Styles.iconn} />
            info@tekkrexim.in
          </p>
          <p>
            <PhoneAndroidIcon className={Styles.iconn} />
            9381280691
          </p>
          <p>
            <BusinessIcon className={Styles.iconn} />
            8-2-248/A/5/30 & 31 Sri, Banjara Hills Rd Number 3, North H Block,
            Journalist Colony, Venkateshwara Hills, Banjara Hills, Telangana
            500034
          </p>
        </div>
      </div>
    </div>
  );
}

export default Address;
